<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                申请编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.applyNum" size="small" placeholder="请输入功能名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                销售人员：
                            </td>
                            <td>
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                联系电话：
                            </td>
                            <td>
                                <el-input v-model="searchItem.userTel" size="small" placeholder="请输入功能名称"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">
                                申请时间：
                            </td>
                            <td style="width: 28%;padding-top: 5px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="width: 300px ;text-align: center;padding-top: 10px" colspan="2">
                                <el-button type="primary" icon="el-icon-search" style="margin-right: 20%" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="150" label="申请编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="公司名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="80" label="公司类型" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="项目名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="80" label="销售人员" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="110" label="联系方式" align="center" show-overflow-tooltip>
                                99999999999
                            </el-table-column>
                            <el-table-column prop="id" width="120" label="部件名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="80" label="部件数量" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="80" label="部件单价" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="80" label="部件总额" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="备注" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <i class="iconfont icontongguo" title="通过" style="margin-right: 20px"
                                           @click="passPartsSales(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="详情"
                                           @click="showPartsSales(scope.row.id)"></i>
                                        <i class="iconfont iconbohui" title="驳回" style="margin-left: 20px"
                                           @click="refusePartsSales(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="ifon-dialog">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    width="30%"
                    :close-on-click-modal="false"
                    center>
                <div class="info-table">
                    <table border width="100%">
                        <tr>
                            <td>申请编号</td>
                            <td>申请编号</td>
                            <td>申请编号</td>
                        </tr>
                    </table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [{
                    id: 1
                }],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                searchItem: {
                    applyNum: '',
                    userName: '',
                    userTel: '',
                    startDate: '',
                    endDate: ''
                },
                saleList: [],
                totalPage: 10,
                infoDialogVisible: false,
                lineHeight: '',
                editTitle: '',
                showTitle: '',
            };
        },
        methods: {
            pageChange(option) {
            },
            reset() {
                this.searchItem = {
                    applyNum: '',
                    userName: '',
                    userTel: '',
                    startDate: '',
                    endDate: ''
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            passPartsSales(id) {

            },
            showPartsSales(id) {
                this.infoDialogVisible = true;
                this.showTitle = '查看《' + id + '》详情信息'
            },
            refusePartsSales(id) {
                this.$prompt('请输入驳回意见', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    center: true
                }).then(({value}) => {
                    this.$message({
                        type: 'success',
                        message: '你的邮箱是: ' + value
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            },
        },
        created() {
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.tableHeight = (window.innerHeight) * 0.72
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .icontongguo {
        color: green;
    }

    .iconbohui {
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 90px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .operation {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>